<template>
  <div class="product-container">
    <!-- banner_cp -->
    <div class="banner_cp" style="background-color: #f6f6f6">
      <div class="margin width1480 hidden">
        <div class="l">
          <dl style="display: block">
            <img
              src="https://img.cdn.wxzhida.cn/shzd-web-site/images/banner_xhx.png"
            />
          </dl>
        </div>
        <div class="r">
          <div class="bg a-fdB">
            <dd>小 黑 侠</dd>
            <dl>
              <p class="no-break-line">
                3.5KW高功率 | 插头桩体双温控 | 家用插座即插即充
              </p>
            </dl>
            <ol class="f">
              <dt class="o">
                <span><i style="background-color: #141414"></i></span>
                <p>黑色</p>
              </dt>
            </ol>
            <a @click="$U.openProductFile('xiaoheixia')">产品手册</a>
          </div>
        </div>
      </div>
    </div>
    <!-- 随车必备 -->
    <div id="g1">
      <div
        class="xhx_scbb"
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/xhx_scbb_bg.jpg);
        "
      >
        <div class="margin width1480 hidden">
          <div class="t">
            <dd class="cp_bt_y">随车必备</dd>
            <dl class="cp_bt_d">有电即能充，摆脱续航焦虑</dl>
            <dd class="cp_bt_y">8A-16A电流切换 220正负极+10%宽电流</dd>
          </div>
          <div class="f">
            <img
              src="https://img.cdn.wxzhida.cn/shzd-web-site/images/xhx_scbb.png"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- xhx_znswk -->
    <div id="g2">
      <div
        class="xhx_znswk"
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/xhx_znswk_bg.jpg);
        "
      >
        <div class="margin width1480 hidden">
          <div class="t">
            <dd class="cp_bt_d">智能双温控 充电安全更稳定</dd>
            <dl class="cp_bt_y">有电的地方即可充电</dl>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- cp_aqfh -->
  <div id="g3">
    <div class="margin width1480 hidden">
      <ul class="cp_aqfh">
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh1.png"
              />
            </ol>
            <dd>过压/欠压保护</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh2.png"
              />
            </ol>
            <dd>过流保护</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh3.png"
              />
            </ol>
            <dd>雷电保护</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh4.png"
              />
            </ol>
            <dd>接地保护</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh5.png"
              />
            </ol>
            <dd>防漏电保护</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh6.png"
              />
            </ol>
            <dd>短路保护</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh7.png"
              />
            </ol>
            <dd>环境电源异常数据记录</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images/cp_aqfh8.png"
              />
            </ol>
            <dd>IP55</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh9.png"
              />
            </ol>
            <dd>过温保护</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh10.png"
              />
            </ol>
            <dd>预约充电</dd>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <!-- xhx_fsfc -->
  <div id="g4">
    <div
      class="xhx_fsfc"
      style="
        background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/xhx_fsfc_bg.jpg);
      "
    >
      <div class="margin width1480 hidden">
        <div class="t">
          <dd class="cp_bt_d">防水防尘</dd>
          <dl class="cp_bt_y">轻松面对雨淋 防止各种情况水的入侵</dl>
        </div>
        <div class="c">
          <img
            src="https://img.cdn.wxzhida.cn/shzd-web-site/images/xhx_fsfc_sz.png"
          />
        </div>
      </div>
      <img
        src="https://img.cdn.wxzhida.cn/shzd-web-site/images/xhx_fsfc_cp.png"
        class="cp"
      />
      <img
        src="https://img.cdn.wxzhida.cn/shzd-web-site/images/xhx_fsfc_sh.png"
        class="bg"
      />
    </div>
  </div>
  <!-- xhx_sswg -->
  <div id="g5">
    <div class="xhx_sswg_tit">
      <dd class="cp_bt_d">时尚外观 轻松收纳</dd>
      <dl class="cp_bt_y">简单便捷 随车出行</dl>
    </div>
    <div
      class="xhx_sswg"
      style="
        background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/xhx_sswg_bg.jpg);
      "
    >
      <div class="margin width1480 hidden">
        <!-- <img src="https://img.cdn.wxzhida.cn/shzd-web-site/images/xhx_znjh.png" class="tp" /> -->
        <div class="r">
          <dd class="cp_bt_d">预约充电 省钱省心</dd>
          <dl class="cp_bt_y">
            预约模式：充电待机状态下，长按按钮24两秒，进入预约设置（预约时间为00小时标识则退出预约充电模式；预约时间为非0，则进入预约充电模式），带您尽享夜间用电低谷期。
          </dl>
        </div>
      </div>
    </div>
  </div>
  <!-- xhx_zyqh -->
  <div id="g6">
    <div
      class="xhx_zyqh"
      style="
        background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/xhx_zyqh_bg.jpg);
      "
    >
      <div class="margin width1480 hidden">
        <div class="l">
          <dd class="cp_bt_d">4档功率 自由切换</dd>
          <dl class="cp_bt_y">
            <p>切换方法：供电插头牢牢插入插电插座中，车端</p>
            <p>断开，长按控制器上的[A] 按钮，充电电流即可切换成功。</p>
            <p>*切换好电流后再插入车端充电</p>
          </dl>
        </div>
        <!-- <img src="https://img.cdn.wxzhida.cn/shzd-web-site/images/xhx_zyqh.png" class="tp" /> -->
      </div>
    </div>
  </div>
  <!-- xhx_aqwd -->
  <div id="g7">
    <div
      class="xhx_aqwd"
      style="
        background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/xhx_aqwd_bg.jpg);
      "
    >
      <div class="margin width1480 hidden">
        <img
          src="https://img.cdn.wxzhida.cn/shzd-web-site/images/xhx_aqwd.png"
          class="tp"
        />
        <div class="r">
          <dd class="cp_bt_d">充电安全更稳定</dd>
          <dl class="cp_bt_y">
            无氧纯铜线材充电稳定 符合国际检测标准防水阻燃 不易发热 充电更稳定
          </dl>
        </div>
      </div>
    </div>
  </div>
  <!-- xhx_lxbz -->
  <div id="g8">
    <div
      class="cp_f_bg xfx_f_bg cp_f_bg_full"
      style="
        background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/xhx_lxbz_bg.jpg);
      "
    >
      <div class="margin width1480 hidden">
        <div class="t">
          <dd>充电不止 旅行不止</dd>
          <dl>充电每一次，记录旅行每一步</dl>
          <ol>
            <a @click="$U.openProductFile('xiaoheixia')"
              >产品手册<i class="fa fa-angle-right"></i
            ></a>
          </ol>
        </div>
        <div class="f">
          <img
            src="https://img.cdn.wxzhida.cn/shzd-web-site/images/xhx_lxbz.png"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive } from "vue";
export default {
  name: "xiaoheixia",
  components: {},
  setup() {
    const { proxy } = getCurrentInstance();
    onMounted(() => {
      document.title = "小黑侠";

      // 滚动到页面最上方
      window.scrollTo(0, 0);

      $(".banner_cp .width1480 .r ol.f dt").click(function () {
        var liindex = $(".banner_cp .width1480 .r ol.f dt").index(this);
        $(this).addClass("o").siblings().removeClass("o");
        $(".banner_cp .width1480 .l dl")
          .eq(liindex)
          .fadeIn(150)
          .siblings("dl")
          .hide();
      });
      $(window).scroll(function () {
        //为页面添加页面滚动监听事件
        var wst = $(window).scrollTop(); //滚动条距离顶端值
        for (var i = 1; i < 20; i++) {
          if ($("#g" + i) && $("#g" + i).offset()) {
            //加循环
            if ($("#g" + i).offset().top <= wst) {
              //判断滚动条位置
              $("#g" + i).addClass("on"); //给当前导航加c类
            } else {
              //$("#p"+i).removeClass('on');
            }
          }
        }
      });
    });
    return {};
  },
};
</script>

<style lang="less" scoped></style>
